import { css } from '@emotion/core';

export const pageSection = css`
  padding-top: 64px;
`;

export const paragraphSection = css`
  padding-top: 30px;
  padding-bottom: 30px;
  clear: both;
`;

export const paragraphTitle = css`
  clear: both;
`;

export const a1screensImg = css`
  @media screen and (min-width: 760px) {
    min-width: 130%;
    margin: 20px 0;
    margin-left: -15%;
  }
`;

export const a1LandingContainer = css`
  max-width: 650px;
  margin: 0 auto;

  @media screen and (max-width: 700px) {
    margin: 0 auto;
  }
`;
