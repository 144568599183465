import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Image, Text } from 'rebass';

import { LandingPageLayout } from '../../../layouts/LandingPage.layout';
import { a1LandingContainer, a1screensImg, pageSection, paragraphSection, paragraphTitle } from './snnpLandingPage.css';

const snnpLandingPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const LANG = i18n.language;

  const metaOgImg = `/img/snnpLanding/snnp-vozzi-facebook-og.jpg`;

  const meta = {
    title: 'Zajedno napred, a Cash Back!',
    description:
      'VOZZi i NIS program lojalnosti Sa nama na putu su ostvarili saradnju i to znači potpuno nove benefite za sve korisnike. Od sada, uz svaku kupovinu ili nadogradnju VOZZi paketa dobijate cash back u vidu bonus poena na vašoj kartici lojalnosti Sa nama na putu, a bonus poene možete iskoristiti za umanjenje računa prilikom kupovine na NIS Petrol i Gazprom benzinskim stanicama.',
    noIndex: true,
  };

  const og = {
    image: metaOgImg,
  };

  const A1PageSection: React.FC<{}> = ({ children = null }) => {
    return (
      <Text as="p" css={paragraphSection}>
        {children || ''}
      </Text>
    );
  };

  const A1ParagraphHeading: React.FC<{}> = ({ children = null }) => {
    return (
      <Heading as="h4" css={paragraphTitle}>
        {children || ''}
      </Heading>
    );
  };

  return (
    <LandingPageLayout
      mainHeading={''}
      minHeight="200px"
      subText={''}
      backgroundImage="bg/snnp-vozzi--zajedno-napred-a-cash-back--desktop--opt.jpg"
      backgroundImageMobile="bg/snnp-vozzi--zajedno-napred-a-cash-back--mobile--opt.jpg"
      buttonText={''}
      meta={meta}
      og={og}
    >
      <Heading as="h1" css={pageSection} pb={80} style={{ textAlign: 'center' }} fontSize={{ md: '42px', sm: '32px' }}>
        Zajedno napred, a Cash Back!
      </Heading>

      <div css={a1LandingContainer}>
        <A1ParagraphHeading>Kupi paket pomoći na putu, i osvoji SNNP bonus poene</A1ParagraphHeading>
        <A1PageSection>
          VOZZi i NIS program lojalnosti Sa nama na putu su ostvarili saradnju i to znači potpuno nove benefite za sve korisnike. Od sada,
          uz svaku kupovinu ili nadogradnju VOZZi paketa dobijate cash back u vidu bonus poena na vašoj kartici lojalnosti Sa nama na putu,
          a bonus poene možete iskoristiti za umanjenje računa prilikom kupovine na NIS Petrol i Gazprom benzinskim stanicama.
        </A1PageSection>

        <A1ParagraphHeading>Kako do bonus poena?</A1ParagraphHeading>
        <A1PageSection>
          <p>Bonus poene ostvarujete u 4 jednostavna koraka:</p>
          <ol>
            <li>Pre kupovine, u VOZZi aplikaciji unesite broj Sa nama na putu kartice u predviđeno polje.</li>
            <li>Izaberite paket pomoći na putu u VOZZi aplikaciji.</li>
            <li>Izvršite plaćanje, i u roku od 48 časova od trenutka kupovine dobijate do 20% bonus poena na Sa nama na putu kartici.</li>
            <li>Iskoristite bonus poene za kupovinu na NIS Petrol i Gazprom benzinskim stanicama.</li>
          </ol>

          <p>Ukoliko kupujete VOZZi paket, ostvarujete pravo na:</p>

          <ul>
            <li>10% cash back za kupovinu paketa VOZZi RS (299 bonus poena)</li>
            <li>15% cash back za kupovinu paketa VOZZi Evropa (675 bonus poena)</li>
            <li>20% cash back za kupovinu paketa VOZZi Evropa Max (1599 bonus poena)</li>
          </ul>

          <p>Ukoliko nadograđujete postojeći VOZZi paket, dobijate 10% cash back:</p>

          <ul>
            <li>sa VOZZi RS na VOZZi Evropa paket (150 bonus poena)</li>
            <li>sa VOZZi 40 na VOZZi RS paket (200 bonus poena)</li>
          </ul>

          <p>Ukoliko nadograđujete postojeći VOZZi paket, dobijate 15% cash back:</p>

          <ul>
            <li>sa VOZZi RS na VOZZi Evropa Max paket (750 bonus poena)</li>
            <li>sa VOZZi Evropa na VOZZi Evropa Max paket (525 bonus poena)</li>
            <li>sa VOZZi 40 na VOZZi Evropa paket (525 bonus poena)</li>
            <li>sa VOZZi 40 na Evropa Max paket (1050 bonus poena)</li>
          </ul>

          <p>Imajte na umu:</p>

          <ul>
            <li>
              Neophodno je da svoju SNNP karticu unesete pre kupovine VOZZi paketa pomoći na putu. Naknadno učitavanje kartice i povezivanje
              sa već izvršenom kupovinom nije moguće.
            </li>
            <li>Bonus poeni ne važe za promocije i dodatne popuste ili promotivne pakete, kao i za stare pakete.</li>
            <li>Benefit je iskazan u bonus poenima, gde je 1 bonus poen = 1 RSD.</li>
            <li>Ponuda se odnosi samo na kupovine obavljene u VOZZi mobilnoj aplikaciji.</li>
          </ul>

          <br />

          <p>
            Cash back se u vidu bonus poena prebacuje na vašu karticu lojalnosti Sa nama na putu. Bonus poene možete iskoristiti za kupovinu
            goriva, ali i ostalih usluga i proizvoda na NIS Petrol i Gazprom benzinskim stanicama.
          </p>

          <br />

          <p>
            Nemate karticu lojalnosti Sa nama na putu? Možete je kreirati. Samo pratite korake u VOZZi aplikaciji i bićete preusmereni kako
            biste dobili svoju karticu.
          </p>

          <br />
          <br />

          <Image src="/img/snnpLanding/snnp-vozzi-app-screens--resized.png" css={a1screensImg} />

          <br />
          <br />
        </A1PageSection>

        <A1ParagraphHeading>Zašto baš VOZZi?</A1ParagraphHeading>
        <A1PageSection>
          <b>VOZZi je prva potpuno digitalizovana pomoć na putu.</b>
          <br />
          <br />
          Sve što je potrebno, obavljate u aplikaciji. Pretplaćujete se na neki od paketa pomoći na putu putem platnih kartica ili uplatnice
          koju Vam sistem generiše. Kada Vam je potrebna pomoć na putu, pozivate je u aplikaciji u svega tri klika. Prilikom traženja pomoći
          aplikacija u realnom vremenu obaveštava najbliže VOZZi partnere (šleperiste i servisere), daje im neophodne informacije, nakon
          čega Vas prvi slobodan partner kontaktira i dolazi na intervenciju.
          <br />
          <br />
          <b>VOZZi je bez limita.</b>
          <br />
          <br />
          VOZZi ima izgrađenu mrežu partnera širom Evrope, sa preko 3000 partnera, koje možete videti unutar same aplikacije. Šlepamo Vas sa
          bilo koje destinacije iz Evrope po ceni paketa koji ste prethodno platili. U slučaju da ne želite šlep do kuće, odnosno da je kvar
          moguće otkloniti na licu mesta ili popravku rešiti u servisu, tu su i pokrića troškova popravki u iznosima od 200 ili 400 EUR, u
          zavisnosti od paketa. Takođe, nećemo Vas šlepati do prvog najbližeg servisa, gde se uobičajeno Vi sami dalje snalazite, već Vas
          šlepamo do našeg prvog partnera, gde su cene troškova usklađenje sa cenama tržišta. U toku trajanja popravke, dobićete smeštaj za
          Vas i sve putnike Vašeg automobila. Za sve ostale detalje, pogledajte informacije unutar VOZZi mobilne aplikacije, ili na VOZZi
          veb sajtu.
          <br />
          <br />
          <Image src="/img/a1Landing/vozzi-brand-masonry.jpg" css={a1screensImg} />
        </A1PageSection>

        <A1ParagraphHeading>Putno zdrastveno osiguranje samo za VOZZi korisnike na 12 meseci</A1ParagraphHeading>
        <A1PageSection>
          VOZZi u saradnji sa Grawe osiguravajućom kućom nudi <strong>godišnje</strong> putno zdravstveno osiguranje, sa neograničenim
          brojem ulazaka i izlazaka iz zemlje, a koje takođe možete kupiti unutar aplikacije u svega nekoliko koraka. Dakle, plaćate samo
          jednom, a svaki Vaš odmor do 15 dana je pokriven u potpunosti. Ma koliko puta išli u inostranstvo, uvek ste pokriveni.
          <br />
          <br />
          Preporučujemo porodični paket, koji ima i ponudu i cenu samo za VOZZi korisnike paketa pomoći na putu.
          <br />
          <br />
          <Image src="/img/a1Landing/VOZZi-GRAWE.png" css={a1screensImg} />
        </A1PageSection>

        <A1ParagraphHeading>Paket može i da se nadogradi</A1ParagraphHeading>
        <A1PageSection>
          Svaki VOZZi paket pomoći na putu može da se nadogradi na veći paket. Preciznije, ukoliko sada kupite Evropa paket, a kasnije
          želite da pređete na Evropa MAX paket, možete isto u svega nekoliko klikova izvršiti doplatu, a Vaš paket će postati Evropa Max
          paket. Ukoliko se pak sada odlučite za nacionalni paket koji pokriva samo teritoriju zemlje Srbije, odnosno sada kupite VOZZi RS
          paket, možete kasnije izvršiti doplatu i preći na Evropa ili Evropa MAX paket u bilo kojem trenutku.
        </A1PageSection>

        <A1ParagraphHeading>Uz svaki paket - poklon paket</A1ParagraphHeading>
        <A1PageSection>
          Uz svaki kupljeni VOZZi Evropa paket dobijate mogućnost da, u roku od 30 dana, poklonite prijateljima dva VOZZi 40 paketa potpuno
          besplatno.
          <br />
          <br />
          <Image src="/img/vozzi-gifts-screens-demo.png" css={a1screensImg} />
          I kako to ne bi bilo sve, uz svaki kupljeni VOZZi Evropa MAX paket dobijate mogućnost da poklonite prijateljima VOZZi RS paket
          pomoći na putu, u roku od 30 dana nakon kupovine Vašeg paketa.
          <br />
          <br />
          Svi poklonjeni paketi važe celih 12 meseci od trenutka aktivacije.
          <br />
          <br />
          Uživajte!
        </A1PageSection>
      </div>
    </LandingPageLayout>
  );
};

export default snnpLandingPage;
